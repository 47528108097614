var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"events-calendar"},[(!_vm.skeleton && _vm.year !== null && _vm.month !== null)?_c('Calendar',{attrs:{"year":_vm.year,"month":_vm.month,"borders":true},scopedSlots:_vm._u([{key:"day",fn:function({ day }){return [_c('div',{staticClass:"calendar-item",on:{"click":function($event){return _vm.handleCalendarItemClick(day)}}},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{class:{
                            'calendar-item__day': true,
                            'calendar-item__day--current': _vm.documentFiltersService.isCurrentDay(day)
                        },domProps:{"textContent":_vm._s(day)}})]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l(((_vm.getDayEvents(day)).slice(0, 2)),function(event,index){return _c('li',{key:event.id + index,on:{"click":e => _vm.viewEvent(e, event.id)}},[_c('EventsCard',{attrs:{"isShort":"","event":event}})],1)}),0)]),(_vm.hiddenEvents(day))?_c('div',{staticClass:"calendar-item__footer"},[_vm._v(" "+_vm._s(_vm.$t('events.popup.dayMore', [_vm.hiddenEvents(day)]))+" ")]):_vm._e()])]}},{key:"day-outside-month",fn:function({day}){return [_c('div',{staticClass:"calendar-item calendar-item__last-previous"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day calendar-item__day--empty",domProps:{"textContent":_vm._s(day)}})])])]}}],null,false,872393318)}):_c('Calendar',{staticClass:"events-calendar--skeleton skeleton-container",attrs:{"year":_vm.year,"month":_vm.month,"borders":""},scopedSlots:_vm._u([{key:"day",fn:function({ day }){return [_c('div',{staticClass:"calendar-item"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day skeleton--simple",class:{ skeleton: _vm.skeleton }},[_vm._v(_vm._s(day))])]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l((2),function(d){return _c('li',{key:d + 'skeleton'},[_c('div',{staticClass:"skeleton skeleton--simple",domProps:{"textContent":_vm._s(_vm.helperService.fill(27))}})])}),0)]),_c('div',{staticClass:"calendar-item__footer"},[_c('span',{staticClass:"skeleton skeleton--simple",domProps:{"textContent":_vm._s(_vm.helperService.fill(17))}})])])]}},{key:"day-outside-month",fn:function({ day }){return [_c('div',{staticClass:"calendar-item"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{class:{
                            'calendar-item__day': true,
                            skeleton: _vm.skeleton,
                        },domProps:{"textContent":_vm._s(day)}})]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l((2),function(d){return _c('li',{key:d + 'skeleton'},[_c('div',{staticClass:"skeleton",domProps:{"textContent":_vm._s(_vm.helperService.fill(27))}})])}),0)]),_c('div',{staticClass:"calendar-item__footer"},[_c('span',{staticClass:"skeleton",domProps:{"textContent":_vm._s(_vm.helperService.fill(17))}})])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }