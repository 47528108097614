var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',{staticClass:"events-hint",scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('events.hintTooltip.heading'))+" ")]},proxy:true},{key:"hoverContent",fn:function(){return [_c('i',{staticClass:"icon-Icons_Qusetion-mark"})]},proxy:true}])},[_c('div',{class:[
        'events-hint__indicator',
        'events-hint__indicator--hotel'
    ]},[_c('div'),_vm._v(" "+_vm._s(_vm.$tc('events.hintTooltip.byHotel'))+" ")]),_c('div',{class:[
            'events-hint__indicator',
            'events-hint__indicator--cluster',
        ]},[_c('div'),_vm._v(" "+_vm._s(_vm.$tc('events.hintTooltip.byCluster'))+" ")]),_c('div',{class:[
            'events-hint__indicator',
            'events-hint__indicator--market',
        ]},[_c('div'),_vm._v(" "+_vm._s(_vm.$tc('events.hintTooltip.byMarket'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }