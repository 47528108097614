
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import EventsCard from '@/modules/events/components/events-manager/events-card.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import EventGroup from '../../interfaces/event-group.enum';

@Component({
    components: {
        LoaderWrapper,
        Calendar,
        EventsCard,
        CustomLoader,
    },
})
export default class EventsCalendar extends Vue {
    @inject(HelperServiceS)
    helperService!: HelperService;

    @inject(DocumentFiltersServiceS)
    documentFiltersService!: DocumentFiltersService;

    @inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(OpenTelemetryServiceS)
    private otelService!: OpenTelemetryService;

    private readonly pageLogName = 'events-calendar';

    beforeMount() {
        this.otelService.startSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName });
    }

    mounted() {
        if (this.eventsManagerService.isLoading) {
            return;
        }

        this.otelService.endSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName }, { sendLogs: true });
    }

    updated() {
        if (this.eventsManagerService.isLoading) {
            return;
        }

        this.otelService.endSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName }, { sendLogs: true });
    }

    get year(): number {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month(): number {
        return this.documentFiltersService.storeState.settings.month;
    }

    get skeleton() {
        return this.eventsManagerService.isLoading;
    }

    getDayEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
        const { countries } = this.eventsManagerService.storeState.settings;
        const showIgnoredOnly = this.$route.query.showIgnored === 'true';

        return this.eventsManagerService.getEventsByDate(date, showIgnoredOnly)
            .filter(event => false
                || countries.includes(event.country!)
                || event.group !== EventGroup.HOLIDAY);
    }

    handleCalendarItemClick(day: Day) {
        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.day-event-modal`
            : 'events-manager.day-event-modal';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                day: String(day),
            },
            query: this.$route.query,
        });
    }

    viewEvent(e: Event, eventId: string) {
        e.stopPropagation();

        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.view`
            : 'events-manager.view';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                eventId,
            },
            query: this.$route.query,
        });
    }

    hiddenEvents(day: Day): number {
        const eventsCount = this.getDayEvents(day).length;
        return eventsCount > 2 ? eventsCount - 2 : 0;
    }
}
